import { parseISO } from "date-fns";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import {
  BlogCard,
  Button,
  Column,
  CurvedSectionDivider,
  CurvedSectionDividerGlow,
  FormattedText,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  Line,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionCustomerLogos,
  SectionQuotes,
  SimpleHero,
  Text,
  Visibility,
} from "../components";

type Props = {
  data: GatsbyTypes.CaseStudiesPageQuery;
};

export const query = graphql`
  query CaseStudiesPage {
    prismicCaseStudiesIndexV2Page {
      data {
        hero_title
        hero_description {
          raw
        }
        hero_contact_button_label
        hero_calculator_button_label

        logos_heading

        case_studies_heading
        case_studies_description {
          raw
        }

        cards {
          card_logo {
            url
          }
          card_date
          card_title
          card_description
          card_mins_read
          card_link
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function CaseStudiesPage({ data }: Props) {
  const page = data.prismicCaseStudiesIndexV2Page?.data;
  if (!page) throw new Error("page missing");

  return (
    <Layout>
      <HelmetSeo {...page} />

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <SimpleHero
            title={page.hero_title}
            description={page.hero_description}
            contactButtonLabel={page.hero_contact_button_label}
            calculatorButtonLabel={page.hero_calculator_button_label}
          />
          <Gutter size={4} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDividerGlow
        position="top"
        glowColor="orange"
        backColor="light1"
      />
      <Section background="dark" tint="light">
        <SectionCustomerLogos
          background="dark"
          title={page.hero_title}
          buttonLabel={null}
          gutterSize={3}
          maxWidth={560}
        />
        <PageMaxWidth>
          <Gutter size={2} />
          <Line color="orange1" />
          <Gutter size={6} />
        </PageMaxWidth>
        <SectionQuotes />
      </Section>
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light1"
      />

      <Section>
        <PageMaxWidth>
          <Gutter size={6} />
          <MaxWidth width={850}>
            <Text typeface="h2" bold as="h2" align="center">
              {page.case_studies_heading}
            </Text>
            <Gutter />
            <FormattedText baseTypeface="h5" textAlign="center">
              <RichText render={page.case_studies_description?.raw} />
            </FormattedText>
            <Gutter size={2} />
          </MaxWidth>

          <Grid>
            {page.cards?.map((card, i) => {
              if (!card) return null;
              const href =
                card.card_link?.substr(0, 5) === "http:"
                  ? card.card_link
                  : undefined;
              return (
                <Column size={4} key={i}>
                  <BlogCard
                    to={href ? undefined : card.card_link}
                    href={href}
                    logoUrl={card.card_logo?.url}
                    date={card.card_date ? parseISO(card.card_date) : undefined}
                    title={card.card_title}
                    description={card.card_description}
                    readTime={card.card_mins_read}
                  />
                  <Visibility hideBelow="md">
                    <Gutter />
                  </Visibility>
                </Column>
              );
            })}
          </Grid>
          <Gutter size={1.5} />
          <HorizontalAlign align="center">
            <Button
              to="/blog"
              colorTheme="blue"
              label="Explore Shipamax Blog"
            />
          </HorizontalAlign>
          <Gutter size={6} />
        </PageMaxWidth>
      </Section>
    </Layout>
  );
}
